import React from 'react';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import {
  FdTypography,
  FdAlert,
  FdExternalLink,
  FdMultiChipTextField,
  FdSelect,
  FdModal,
  globalStore,
  useSnapshot,
  PERMISSIONS_AFFILIATED_DISPLAY,
  PERMISSIONS_AFFILIATED,
  FdCheckbox,
  USER_PERMISSIONS_EXPLAINER,
} from '@fifthdomain/fe-shared';
import {
  initialValues,
  validationSchema,
} from '../../validation-schemas/Affiliated';
import {
  snakeCaseToTitleCase,
  titleCaseToSnakeCase,
} from '../../shared/utils/stringUtils';
import { addUsers } from '../../graphql/mutations';
import {
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';

const InviteAffiliatedUsersModal = ({
  showModal,
  setShowModal,
  allGroups,
  refetchQueries,
  allAffiliatedUsers,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const { orgMaxInvitees } = globalSnap;
  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control, reset, getValues, trigger, watch } = hookFormMethods;

  const [addUsersMutation, { loading: inviteUsersLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: (_data) => {
        reset();
        setShowModal(false);
        successToastMessage(
          'Success! User(s) invited to the platform with affiliation request',
        );
        refetchQueries();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );
  const watchEmails = watch('emails');
  const watchConfirm = watch(['confirm1', 'confirm2']);
  const emailCount = watchEmails?.length || '-';

  const canInviteUsers = orgMaxInvitees
    ? orgMaxInvitees >= allAffiliatedUsers?.length + watchEmails?.length
    : true;

  return (
    <FdModal
      size="lg"
      title="Invite Affiliated Users"
      confirm="INVITE"
      dismiss="CANCEL"
      open={showModal}
      confirmLoading={inviteUsersLoading}
      disableConfirm={
        inviteUsersLoading || !watchConfirm.every(Boolean) || !canInviteUsers
      }
      onConfirm={async () => {
        const valid = await trigger();
        if (valid) {
          const { emails, groups, permissions } = getValues();
          const permissionValues =
            permissions?.map((item) =>
              titleCaseToSnakeCase(
                PERMISSIONS_AFFILIATED[
                  titleCaseToSnakeCase(
                    item === 'Create Content' ? 'CREATE' : item,
                  )
                ],
              ),
            ) || [];
          const groupValues =
            allGroups
              ?.filter((g) => groups.includes(g.name))
              ?.map((g) => g?.id) || [];

          addUsersMutation({
            variables: {
              emails,
              orgId: globalSnap?.orgId,
              participantType: 'INTERNAL',
              permissions:
                permissionValues?.length > 0
                  ? permissionValues
                  : ['PARTICIPATE', 'MANAGE_PROFILE'],
              affliationAction: 'NEW_REQUEST',
              ...(groupValues?.length > 0 ? { groups: groupValues } : {}),
            },
          });
        }
      }}
      onDismiss={() => {
        reset();
        setShowModal(false);
      }}
    >
      <FormProvider {...hookFormMethods}>
        <form>
          <Box>
            <FdTypography
              variant="body2"
              style={{ position: 'absolute', top: '60px' }}
            >
              To understand more about affiliate users, refer to
              <FdExternalLink href={USER_PERMISSIONS_EXPLAINER} noUnderline>
                this
              </FdExternalLink>
              article.
            </FdTypography>
            <Box mt={2} className="flex flex-col gap-y-2">
              <FdAlert
                variant="info"
                message="Duplicate emails, and users who have already been invited and have had affiliation requests sent, will be automatically removed from this invitation instance."
              />
              <FdAlert
                variant="warning"
                message="The link to affiliate expires in 30 days. If the invited users do not confirm their affiliation within this time, managers will have to resend the invitation."
              />
              <Box mt={1}>
                <FdTypography variant="subtitle1">
                  Invitee Email Addresses
                </FdTypography>
                <FdTypography variant="captiontext1" color="secondary">
                  Paste in email addresses of users to want to invite to the FD
                  platform to participate in either competitions, assessments or
                  courses. Press enter after you have pasted the email
                  address(es).
                </FdTypography>
                <Box my={1}>
                  <Controller
                    control={control}
                    name="emails"
                    render={({
                      field: { ref, value, onChange, ...rest },
                      fieldState: { error },
                    }) => {
                      const allErrors =
                        error?.length > 0
                          ? [...new Set(error?.map((e) => e.message))]
                          : [error?.message];

                      return (
                        <>
                          <FdMultiChipTextField
                            id="emails"
                            width="99%"
                            height="130px"
                            label=""
                            required
                            values={value}
                            setValues={onChange}
                            {...rest}
                            inputRef={ref}
                          />
                          {allErrors &&
                            allErrors?.map((message) => (
                              <FdTypography
                                variant="captiontext1"
                                style={{ color: 'red' }}
                              >
                                {message}
                              </FdTypography>
                            ))}
                        </>
                      );
                    }}
                  />
                </Box>
                {!canInviteUsers && (
                  <Box my={1}>
                    <FdAlert
                      variant="warning"
                      message="The number of email addresses exceeds the invitation limit for your current plan. To invite more affiliated users, remove existing users or revoke pending invitations. Alternatively, contact FifthDomain to discuss upgrading your plan."
                    />
                  </Box>
                )}
                <Box mb={2}>
                  <Box className="flex items-center gap-x-2">
                    <FdTypography variant="subtitle1">
                      Assign Managerial Permissions
                    </FdTypography>
                    <FdTypography variant="captiontext1" color="secondary">
                      Optional
                    </FdTypography>
                  </Box>
                  <Box mb={1}>
                    <FdTypography variant="captiontext1" color="secondary">
                      You can explore the
                      <FdExternalLink
                        href={USER_PERMISSIONS_EXPLAINER}
                        noUnderline
                      >
                        User Permissions Explainer
                      </FdExternalLink>
                      for in-depth insights into permissions on the FifthDomain
                      platform.
                    </FdTypography>
                  </Box>
                  <Controller
                    control={control}
                    name="permissions"
                    render={({ field }) => (
                      <FdSelect
                        inputTitle=""
                        id="groups"
                        options={Object.keys(
                          PERMISSIONS_AFFILIATED_DISPLAY,
                        ).map((key) =>
                          snakeCaseToTitleCase(
                            PERMISSIONS_AFFILIATED_DISPLAY[key] === 'CREATE'
                              ? 'Create Content'
                              : PERMISSIONS_AFFILIATED_DISPLAY[key],
                          ),
                        )}
                        multiple
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Box className="flex items-center gap-x-2">
                    <FdTypography variant="subtitle1">
                      Assign Group(s) to the participants being invited
                    </FdTypography>
                    <FdTypography variant="captiontext1" color="secondary">
                      Optional
                    </FdTypography>
                  </Box>
                  <Box mb={1}>
                    <FdTypography variant="captiontext1" color="secondary">
                      The groups selected from the dropdown menu will be
                      assigned to all users whose email addresses are added in
                      the &apos;Invite Email Addresses&apos; section above.
                    </FdTypography>
                  </Box>
                  <Controller
                    control={control}
                    name="groups"
                    render={({ field }) => (
                      <FdSelect
                        inputTitle=""
                        id="permissions"
                        options={allGroups?.map((g) => g?.name)}
                        multiple
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Box className="flex items-center gap-x-2">
                    <FdTypography variant="subtitle1">
                      {`Acknowledge terms to proceed with sending Affiliation
                      Requests to ${emailCount} user(s).`}
                    </FdTypography>
                  </Box>
                  <Controller
                    control={control}
                    name="confirm1"
                    render={({ field: { ref, value, ...fieldProps } }) => (
                      <FdCheckbox
                        label="I understand and acknowledge that upon confirming their affiliation, these users will gain access to the Organisation's Hall of Fame."
                        inputRef={ref}
                        checked={value}
                        {...fieldProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="confirm2"
                    render={({ field: { ref, value, ...fieldProps } }) => (
                      <FdCheckbox
                        label="I recognise and accept that affiliated users have the right to disaffiliate from this organisation at any moment of their choosing."
                        inputRef={ref}
                        checked={value}
                        {...fieldProps}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </FdModal>
  );
};

InviteAffiliatedUsersModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  allGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchQueries: PropTypes.func.isRequired,
  allAffiliatedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default InviteAffiliatedUsersModal;
