export const listGroupsWithUsers = /* GraphQL */ `
  query listGroupsWithUsers(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          items {
            user {
              id
              name
              email
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTasksByOrgId = /* GraphQL */ `
  query listTasksByOrgId(
    $orgId: ID!
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrgId(
      orgId: $orgId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tags {
          items {
            tagId
            taskId
          }
        }
      }
      nextToken
    }
  }
`;

export const listGroupsByOrgId = /* GraphQL */ `
  query ListGroupsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAffliationsByOrgId = /* GraphQL */ `
  query ListAffliationsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        status
        user {
          id
          name
          email
          alias
          permissions
          assessments {
            items {
              startedOn
              status
              assessment {
                orgId
                participantEventType
              }
            }
          }
          courses {
            items {
              course {
                orgId
                id
                status
                modulePartProgresses {
                  items {
                    status
                    courseId
                    userId
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
