import Yup from '../Common';

export const initialValues = {
  tagName: '',
  tagDescription: '',
  tagType: { id: 'tagType', value: '' },
  tagColor: 'YELLOW',
};

export const validationSchema = Yup.object().shape({
  tagName: Yup.string()
    .required('Please enter a tag name.')
    .max(50, 'Tag name must be 50 characters or less.')
    .noWhitespace('Please enter a tag name.'),
  tagDescription: Yup.string()
    .test(
      'noWhitespaceNonRequired',
      'Please enter a valid tag description',
      (value) => !value || (value && value.trim().length),
    )
    .max(100, 'Tag description must be 100 characters or less.'),
  tagType: Yup.object()
    .shape({
      id: Yup.string(),
      value: Yup.string().required('Please select a tag type.'),
    })
    .nullable()
    .required('Please select a tag type.'),
  tagColor: Yup.string().nullable(),
});
