import React, { useEffect, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { AuthContext } from '@fifthdomain/fe-shared';

const UserManagementQuizRoute = ({ component: Component, ...rest }) => {
  const { user, setUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(function (userData) {
        setUser(userData);
      })
      .catch((err) => {
        console.log('error: ', err);
        history.push('/');
      });
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

UserManagementQuizRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default UserManagementQuizRoute;
