import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from '@material-ui/core';
import singleSpa from 'single-spa';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, gql } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  BasePage,
  FdModal,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  Authorization,
  PERMISSIONS_AFFILIATED,
} from '@fifthdomain/fe-shared';
import {
  getValidationSchema,
  initialValues,
} from '../validation-schemas/Squads';
import scrollToTop from '../shared/utils/scroll';
import SquadDetails from '../components/Squad/SquadDetails';
import SquadUsers from '../components/Squad/SquadUsers';
import { listAffliationsByOrgId } from '../queries/customQueries';
import { snakeCaseToTitleCase } from '../shared/utils/stringUtils';
import { createSquad, createSquadMember } from '../graphql/mutations';
import { errorToastMessage, successToastMessage } from '../shared/utils/toast';
import { addUsers } from '../components/Squad/utils';
import { invalidateSquadQuery } from '../queries/invalidateQueries';
import { listSquadsByOrgId } from '../graphql/queries';

const useStyles = makeStyles(() => ({
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const steps = ['Add Squad Details', 'Add Squad Users'];

const CreateSquad = () => {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const classes = useStyles();
  const { orgId, permissions } = useSnapshot(globalStore);
  const canCreateSquad = Authorization.hasPermission(permissions, [
    PERMISSIONS_AFFILIATED.MANAGE_USERS,
  ]);

  const { data: affiliatedUsersData } = useQueryRecursive(
    gql(listAffliationsByOrgId),
    {
      variables: {
        orgId,
        limit: 500,
      },
      skip: !orgId,
      onCompleted: () => {
        if (!canCreateSquad) {
          singleSpa.navigateToUrl('/landing');
        }
      },
    },
  );

  const { data: squadsByOrgIdData } = useQueryRecursive(
    gql(listSquadsByOrgId),
    {
      variables: {
        orgId,
        limit: 500,
      },
      skip: !orgId,
    },
  );
  const allSquads = squadsByOrgIdData?.listSquadsByOrgId?.items || [];
  const allSquadUsers = allSquads
    ?.map((s) => s?.members?.items || [])
    ?.flat()
    ?.filter((u) => u?.role !== 'VIEWER')
    ?.map((u) => u?.userId);
  const allSquadNames = allSquads?.map((s) => s?.name?.toLowerCase());

  const [createSquadMemberMutation, { loading: createSquadMemberLoading }] =
    useMutation(gql(createSquadMember));

  const addRoleType = (_users, _role) =>
    _users?.map((u) => ({ ...u, role: _role }));

  const [createSquadMutation, { loading: createSquadLoading }] = useMutation(
    gql(createSquad),
    {
      onCompleted: (data) => {
        const { id: squadId } = data?.createSquad;
        // eslint-disable-next-line no-use-before-define
        const values = getValues();
        const { managers, viewers, members } = values;
        const allUsers = [
          ...addRoleType(managers, 'MANAGER'),
          ...addRoleType(viewers, 'VIEWER'),
          ...addRoleType(members, 'MEMBER'),
        ];

        addUsers(squadId, allUsers, createSquadMemberMutation)
          .then(() => {
            successToastMessage('Success! Squad created');
            // eslint-disable-next-line no-use-before-define
            reset();
            // re-query list groups and invited users
            invalidateSquadQuery();
            singleSpa.navigateToUrl('/user-management/squads');
          })
          .catch((error) => {
            errorToastMessage(error.message);
          });
      },
    },
  );
  const validationSchema = getValidationSchema({ squadNames: allSquadNames });

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    formState: { isDirty },
    reset,
    getValues,
    trigger,
    handleSubmit,
  } = hookFormMethods;

  const onSubmit = async () => {
    const result = await trigger();
    if (result) {
      const values = getValues();
      const { squadName, squadDescription } = values;
      await createSquadMutation({
        variables: {
          input: {
            name: squadName,
            description: squadDescription,
            orgId,
          },
        },
      });
    }
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['squadName', 'squadDescription']);
        break;
      }
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    // on click cancel of first page go back to list page
    if (activeStep === 0) {
      singleSpa.navigateToUrl('/user-management/squads');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  const allAffiliatedUsers =
    _.uniqBy(
      affiliatedUsersData?.listAffliationsByOrgId?.items
        ?.filter((u) => u?.status === 'ACTIVE')
        ?.map((u) => {
          return {
            id: u?.userId,
            name: u?.user?.name,
            email: u?.user?.email,
            userId: u?.userId,
            permissions: u?.user?.permissions
              ?.filter((p) => !['PARTICIPATE', 'MANAGE_PROFILE'].includes(p))
              ?.map((p) => snakeCaseToTitleCase(p)),
          };
        }),
      'userId',
    ) || [];
  const loading = createSquadLoading || createSquadMemberLoading;

  return (
    <BasePage
      heading="Create a Squad"
      data-cy="create-squad-base-page"
      breadCrumbs={[
        { url: '/user-management', name: 'Home' },
        { url: '/user-management/squads', name: 'User / Squads' },
      ]}
      currentPageBreadcrumbLabel="Create Squad"
      linkComponent={RouterLink}
    >
      <Box width="650px">
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...hookFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            {
              0: <SquadDetails />,
              1: (
                <SquadUsers
                  allUsers={allAffiliatedUsers}
                  allSquadUsers={allSquadUsers}
                />
              ),
            }[activeStep]
          }
          <Box display="flex" justifyContent="space-between" pb={3}>
            <FdButton size="large" variant="secondary" onClick={handleBack}>
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </FdButton>
            <Box>
              {activeStep === 1 && (
                <FdButton size="large" type="submit" disabled={loading}>
                  {loading && <CircularProgress size={16} className="mr-2" />}
                  Create Squad
                </FdButton>
              )}
              {activeStep < 1 && (
                <FdButton
                  size="large"
                  onClick={handleNext}
                  style={{ marginLeft: '1rem' }}
                >
                  Next
                </FdButton>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
      <NavigationPrompt
        when={(crntLocation, nextLocation) =>
          isDirty && crntLocation.pathname !== nextLocation.pathname
        }
        afterCancel={() => {
          if (window.location.pathname !== '/user-management/create-squad') {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default CreateSquad;
