export const capitalize = (str) =>
  str[0].toUpperCase() + str.substring(1).toLowerCase();

export const formatListWithConjunction = (arr) => {
  if (!arr.length) return '';

  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`;
  }

  return `${arr.slice(0, -1).join(', ')} and ${arr[arr.length - 1]}`;
};

export const snakeCaseToTitleCase = (str) => {
  return (
    str
      ?.replace('_', ' ')
      ?.toLowerCase()
      ?.replace(/\b\w/g, (c) => c.toUpperCase()) || ''
  );
};

export const titleCaseToSnakeCase = (str) => {
  return str?.replace(/\s+/g, '_')?.toUpperCase();
};

export const getCommaSeparated = (arr, includeQuotes = false) => {
  const arrQ = includeQuotes ? arr.map((a) => `"${a}"`) : arr;
  if (arrQ.length === 1) return arrQ[0];
  const firsts = arrQ.slice(0, arrQ.length - 1);
  const last = arrQ[arrQ.length - 1];
  return `${firsts.join(', ')} and ${last}`;
};

// input string array
// output - "Intrusion Detection +2" or "Intrusion Detection, Firewall, VPN, +2"
export const getCommaSeparatedPlusSuffix = (strings = [], showItems = 1) => {
  if (strings.length <= showItems) {
    return strings.join(', ');
  }
  const extraValues = strings.length - showItems;
  const printedValues = strings.slice(0, showItems);
  return `${printedValues} +${extraValues}`;
};
