import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import {
  FdBreadcrumbs,
  FdTypography,
  AuthContext,
  FdLoadingSpinner,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import QuizTable from '../components/Quiz/QuizTable';
import { listQuizesByOrgId, getUserById } from '../graphql/queries';
import { getDateTimeZoneCustom } from '../shared/utils/dateUtils';

const ManageQuiz = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const onDeleteRow = () => {};

  const handleNavigateCreate = () => {
    history.push('/user-management/quizzes/create');
  };

  const { data: getUserData } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
  });

  const orgId = getUserData?.getUserById?.items[0].orgId;
  const { data: quizData, loading: quizLoading } = useQueryRecursive(
    gql(listQuizesByOrgId),
    {
      variables: {
        limit: 500,
        orgId,
      },
    },
  );

  if (quizLoading) {
    return <FdLoadingSpinner />;
  }

  const quizzes = quizData?.listQuizesByOrgId?.items.map((quiz) => {
    return {
      id: quiz.id,
      name: quiz.name,
      creator: quiz?.user?.name,
      createdAt: getDateTimeZoneCustom(quiz.createdAt),
      course: 0,
      attempts: 0,
    };
  });
  return (
    <Box>
      <FdBreadcrumbs
        linkChain={[{ url: '/user-management', name: 'Home' }]}
        currentPage="User Management / Manage Quiz"
        linkComponent={RouterLink}
      />
      <Box my={2}>
        <FdTypography variant="h2">Manage Quizzes</FdTypography>
      </Box>
      <QuizTable
        rows={quizzes}
        onDeleteRow={onDeleteRow}
        handleNavigateCreate={handleNavigateCreate}
      />
    </Box>
  );
};

export default ManageQuiz;
