import React from 'react';
import { useTheme } from '@material-ui/core';

const AssessmentsIcon = () => {
  const theme = useTheme();
  const { assessments } = theme?.fdProColors?.event || {};

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="4" fill={assessments} />
      <g opacity="0.85">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 6H17.82C17.4 4.84 16.3 4 15 4C13.7 4 12.6 4.84 12.18 6H8C6.9 6 6 6.9 6 8V24C6 25.1 6.9 26 8 26H22C23.1 26 24 25.1 24 24V8C24 6.9 23.1 6 22 6ZM15 6C15.55 6 16 6.45 16 7C16 7.55 15.55 8 15 8C14.45 8 14 7.55 14 7C14 6.45 14.45 6 15 6ZM8 8V24H22V8H20V11H10V8H8Z"
          fill="black"
          fillOpacity="0.87"
        />
      </g>
    </svg>
  );
};

export default AssessmentsIcon;
