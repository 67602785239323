import { ReactQueryClient } from '@fifthdomain/fe-shared';

export const invalidateGroupQuery = () => {
  ReactQueryClient.removeQueries(['ListUsersByOrgId']);
  ReactQueryClient.removeQueries(['ListUsersByGroupId']);
  ReactQueryClient.removeQueries(['ListInvitedUsersByGroupId']);
  ReactQueryClient.removeQueries(['ListGroupsByOrgId']);
};

export const invalidateAffiliatedQuery = () => {
  ReactQueryClient.removeQueries(['ListAffliationRequestsByOrgId']);
};

export const invalidateSquadQuery = () => {
  ReactQueryClient.removeQueries(['ListSquadsByOrgId']);
};
