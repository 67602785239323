import React from 'react';
import { useTheme } from '@material-ui/core';

const CoursesIcon = () => {
  const theme = useTheme();
  const { courses } = theme?.fdProColors?.event || {};

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="4" fill={courses} />
      <g opacity="0.85">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 24.9502V20.1402C19.79 19.1002 21 17.1702 21 14.9502C21 11.6402 18.31 8.9502 15 8.9502C11.69 8.9502 9 11.6402 9 14.9502C9 17.1702 10.21 19.1002 12 20.1402V24.9502H18ZM7 15.9502H4V13.9502H7V15.9502ZM7.96 6.50019L6.55 7.9102L8.34 9.7102L9.75 8.3002L7.96 6.50019ZM16 6.9502V4.00019H14V6.9502H16ZM23 13.9502H26V15.9502H23V13.9502ZM22.03 6.49019L20.24 8.2902L21.64 9.6902L23.44 7.9002L22.03 6.49019Z"
          fill="black"
          fillOpacity="0.87"
        />
      </g>
    </svg>
  );
};

export default CoursesIcon;
