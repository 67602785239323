import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  FdTable,
  FdModal,
  FdIcons,
  FdTypography,
} from '@fifthdomain/fe-shared';
import singleSpa from 'single-spa';
import { warningToastMessage } from '../../shared/utils/toast';

const QuizTable = ({ rows = [], onDeleteRow, handleNavigateCreate }) => {
  const [quizToDelete, setQuizToDelete] = useState(undefined);
  const columns = [
    { field: 'name', width: 250, headerName: 'Name' },
    { field: 'creator', width: 250, headerName: 'Creator' },
    { field: 'attempts', width: 250, headerName: 'No of Attempts' },
    {
      field: 'course',
      width: 250,
      headerName: 'No of Courses',
    },
    {
      field: 'createdAt',
      width: 250,
      headerName: 'Date Created',
    },
  ];
  const { Warning } = FdIcons;

  const actions = [
    {
      label: 'View',
      show: () => true,
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(`/user-management/quizzes/view/${id}`);
      },
    },
    {
      label: 'Delete',
      show: () => true,
      onClick: (row) => setQuizToDelete(row),
    },
    {
      label: 'hide',
      show: () => false,
      onClick: () => {},
    },
  ];

  return (
    <Box height="715px" bgcolor="#fff">
      <FdTable
        toolbarSettings={{
          title: 'List of all Quizzes',
          filterButton: true,
          searchBox: true,
          headerActions: [
            { label: 'Create Quiz', onClick: handleNavigateCreate },
          ],
        }}
        rows={rows}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="usermanagement-list-quizzes"
      />
      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <Warning
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Delete Quiz?</span>
          </Box>
        }
        description="Are you sure you want to delete the Quiz?"
        confirm="OK"
        dismiss="CANCEL"
        open={quizToDelete}
        onConfirm={() => {
          onDeleteRow(quizToDelete);
          setQuizToDelete(undefined);
        }}
        onDismiss={() => {
          setQuizToDelete(undefined);
          warningToastMessage('Quiz not deleted');
        }}
      >
        <Box mt={2}>
          <FdTypography variant="body1" color="secondary">
            The quiz will be permanently removed from the platform, and from any
            courses.
          </FdTypography>
        </Box>
      </FdModal>
    </Box>
  );
};

QuizTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  handleNavigateCreate: PropTypes.func,
};

export default QuizTable;
