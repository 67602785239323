import { format as dateFormat, utcToZonedTime } from 'date-fns-tz';

const userTimeProfile = Intl.DateTimeFormat().resolvedOptions();
const { timeZone } = userTimeProfile;

const defaultDateFormatPattern = 'dd/MM/yyyy';

export const sortedByDate = (arr) =>
  arr?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) || [];

export const getDateTimeZone = (dateTime) => {
  return utcToZonedTime(new Date(dateTime), timeZone);
};

export const getDateTimeZoneCustom = (dateTime, format) => {
  const dateFormatPattern = format || defaultDateFormatPattern;
  return dateFormat(getDateTimeZone(dateTime), dateFormatPattern);
};
