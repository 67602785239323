import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  FdTypography,
  FdButton,
  FdIcons,
  FdTextField,
} from '@fifthdomain/fe-shared';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DUMMY_DATA } from '../../constants';

const QuizDetails = ({ details }) => {
  const [isEditDetails, setIsEditDetails] = useState(false);
  const { AvTimer } = FdIcons;
  const { quizId } = useParams();
  const quiz = DUMMY_DATA.find((item) => item.id === quizId);

  const {
    control,
    reset,
    trigger,
    formState: { isDirty },
    setValue,
  } = useFormContext();

  // const quizName = useWatch({
  //   control,
  //   name: 'quizName',
  // });
  // // setValue('quizName', details.name);
  // const quizTimeLimit = useWatch({
  //   control,
  //   name: 'quizTimeLimit',
  // });
  // setValue('quizTimeLimit', details.duration);

  return (
    <Box p={3} bgcolor="#fff" mb={4}>
      {!quiz && <FdTypography variant="h3">Add Quiz Details</FdTypography>}
      {quiz && (
        <Box display="flex" justifyContent="space-between">
          <FdTypography variant="h3">View {quizName}</FdTypography>
          <Box display="flex">
            <AvTimer />
            <Box ml={2}>
              <FdTypography>{quiz.quizTimeLimit}</FdTypography>
            </Box>
            <Box ml={4}>
              <FdTypography>Total Ponits: {details?.point}</FdTypography>
            </Box>
            <Box ml={4}>
              {isEditDetails ? (
                <Box display="flex">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        setIsEditDetails(false);
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={() => {
                        setIsEditDetails(false);
                      }}
                    >
                      Save
                    </FdButton>
                  </Box>
                </Box>
              ) : (
                <FdButton
                  size="small"
                  onClick={() => {
                    setIsEditDetails(true);
                  }}
                >
                  Edit
                </FdButton>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box mt={2}>
        <FdTypography variant="body1">
          {isEditDetails ? 'Quiz Name' : 'Name'}
        </FdTypography>
        <Box mt={1}>
          {isEditDetails || !quiz ? (
            <Controller
              control={control}
              name="quizName"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <FdTextField
                  id="quizName"
                  placeholder="Quiz name must be 25 characters or less"
                  fullWidth
                  error={error}
                  required
                  helperText={error && error.message}
                  {...rest}
                  inputRef={ref}
                />
              )}
            />
          ) : (
            <FdTypography variant="body1" color="secondary">
              {details?.name}
            </FdTypography>
          )}
        </Box>
        <Box mt={2}>
          <FdTypography variant="body1">
            {isEditDetails ? 'Quiz Description ' : 'Description / Instructions'}
            {isEditDetails && (
              <FdTypography variant="caption" color="secondary">
                optional
              </FdTypography>
            )}
          </FdTypography>
          <Box mt={1}>
            {isEditDetails || !quiz ? (
              <Controller
                control={control}
                name="quizDescription"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <FdTextField
                    id="quizDescription"
                    placeholder="Quiz name must be 25 characters or less"
                    fullWidth
                    multiline
                    rows={3}
                    error={error}
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              />
            ) : (
              <FdTypography variant="body1" color="secondary">
                {details?.description}
              </FdTypography>
            )}
          </Box>
        </Box>
        <Box mt={2}>
          <FdTypography variant="body1">
            Quiz Time Limit (mins)
            <FdTypography variant="caption" color="secondary">
              optional
            </FdTypography>
          </FdTypography>
          <Box mt={2} mb={2}>
            <Controller
              control={control}
              name="quizTimeLimit"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <FdTextField
                  id="quizTimeLimit"
                  defaultValue={1}
                  type="number"
                  width="134px"
                  error={error}
                  helperText={error && error.message}
                  {...rest}
                  inputRef={ref}
                />
              )}
            />
          </Box>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="allowMultipleAttempts"
                render={({ field: { ref, ...rest } }) => (
                  <Checkbox
                    id="allowMultipleAttempts"
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            }
            label={
              <FdTypography variant="body1" color="secondary">
                Allow multiple attempts
              </FdTypography>
            }
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="returnResults"
                render={({ field: { ref, ...rest } }) => (
                  <Checkbox id="returnResults" inputRef={ref} {...rest} />
                )}
              />
            }
            label={
              <FdTypography variant="body1" color="secondary">
                Return results to Users on completion
              </FdTypography>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QuizDetails;
