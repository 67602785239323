import React, { useState, useEffect } from 'react';
import { Box, Checkbox } from '@material-ui/core';
import {
  FdTypography,
  FdButton,
  FdTextField,
  FdChip,
} from '@fifthdomain/fe-shared';

const FreeTextQuestion = ({
  setShowCompetencyModal,
  isEdit,
  question,
  selectedContexts,
  handleDeleteComp,
}) => {
  const [questionName, setQuestionName] = useState('');

  useEffect(() => {
    setQuestionName(question);
  }, [isEdit]);
  return (
    <Box width="100%">
      <FdTypography size="small" variant="caption" color="secondary">
        Type quiz question here
      </FdTypography>
      <Box mt={2}>
        <FdTypography variant="body1">Question</FdTypography>
        <FdTextField
          id="question-name"
          fullWidth
          value={questionName}
          onChange={(e) => setQuestionName(e.target.value)}
        />
      </Box>
      <Box mt={2}>
        <FdTypography variant="body1">Correct Answer</FdTypography>
        <FdTextField id="question-name" fullWidth />
      </Box>
      <Box display="flex" alignItems="center" mt={2}>
        <Checkbox />
        <FdTypography variant="body1" color="secondary">
          Case sensitive
        </FdTypography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox />
        <FdTypography variant="body1" color="secondary">
          Whitespace sensitive
        </FdTypography>
      </Box>
      <Box mt={2} mb={2}>
        <FdTypography>Points for the Question</FdTypography>
        <Box>
          <FdTextField
            id="time-limit"
            defaultValue={1}
            required
            type="number"
            width="134px"
          />
        </Box>
      </Box>
      <FdButton onClick={() => setShowCompetencyModal(true)}>
        ADD COMPETENCY
      </FdButton>
      <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
        {selectedContexts.length
          ? selectedContexts.map((context) => (
              <Box mr={1} mb={1}>
                <FdChip
                  label={context}
                  onDelete={() => handleDeleteComp(context)}
                />
              </Box>
            ))
          : null}
      </Box>
      <Box display="flex" mt={2}>
        <Box pr={1} ml="auto">
          <FdButton variant="secondary" size="small">
            CANCEL
          </FdButton>
        </Box>
        <Box>
          <FdButton variant="primary" size="small">
            Save
          </FdButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FreeTextQuestion;
