import * as Yup from 'yup';

// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
  // eslint-disable-next-line react/no-this-in-sfc
  return this.test({
    name: 'noWhitespace',
    message,
    test: (value) => value && value.trim().length,
  });
});

export default Yup;
