import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  FdTypography,
  FdButton,
  FdTab,
  FdBreadcrumbs,
  useQueryRecursive,
  FdProgress,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { TagsTable, CreateTag } from '../components/TagDirectory';
import { listTagsByOrgId } from '../graphql/queries';
import { createTag as createTagDirectory } from '../graphql/mutations';
import { getDateTimeZoneCustom, sortedByDate } from '../shared/utils/dateUtils';
import {
  successToastMessage,
  warningToastMessage,
} from '../shared/utils/toast';
import { TAG_TYPE } from '../constants';
import { initialValues } from '../validation-schemas/TagDirectory';
import { listTasksByOrgId } from '../queries/customQueries';

const TagDirectory = () => {
  const { orgId, userId } = useSnapshot(globalStore);
  const [createTag, setCreateTag] = useState(false);

  const {
    data: listTagsByOrgIdData,
    loading: listTagsByOrgIdRefetchLoading,
    refetch: listTagsByOrgIdRefetch,
  } = useQueryRecursive(gql(listTagsByOrgId), {
    variables: {
      orgId,
    },
    skip: !orgId,
  });

  const { data: listTasksByOrgIdData, loading: listTasksByOrgIdLoading } =
    useQueryRecursive(gql(listTasksByOrgId), {
      variables: {
        orgId,
        limit: 500,
      },
      skip: !orgId,
    });

  const [createTagDirectoryMutation] = useMutation(gql(createTagDirectory), {
    onCompleted: () => {
      setCreateTag(false);
      successToastMessage('Success! Tag Created');
      listTagsByOrgIdRefetch();
    },
  });

  if (listTagsByOrgIdRefetchLoading || listTasksByOrgIdLoading) {
    return <FdProgress />;
  }
  const tasksWithTags = listTasksByOrgIdData?.listTasksByOrgId?.items.filter(
    (t) => t.tags?.items?.length > 0,
  );

  const tagDirectory =
    sortedByDate([
      ...(listTagsByOrgIdData?.listTagsByOrgId?.items.map((c) => ({
        tagName: c.name,
        tagType:
          c.type === 'USER_TAG'
            ? { value: TAG_TYPE.USER }
            : { value: TAG_TYPE.CHALLENGE },
        tagDescription: c?.description,
        tagCreator: c.user?.name,
        tagDateCreated: getDateTimeZoneCustom(c.createdAt),
        tagColor: c.color,
        tagCount: (tasksWithTags ?? []).reduce(
          (acc, t) =>
            acc + Number(t.tags?.items?.some((tg) => tg.tagId === c.id) || 0),
          0,
        ),
        ...c,
      })) || []),
    ]) || [];

  return (
    <Box>
      <FdBreadcrumbs
        linkChain={[{ url: '/user-management', name: 'Home' }]}
        currentPage="Tag Directory / All Tags"
        linkComponent={RouterLink}
      />
      <Box display="flex" justifyContent="space-between">
        <FdTypography variant="h2">Tag Directory</FdTypography>
        <FdButton
          variant="primary"
          size="large"
          onClick={() => {
            setCreateTag(true);
          }}
        >
          Create Tag
        </FdButton>
      </Box>
      <FdTab
        label={[
          {
            label: 'All Tags',
            path: '/user-management/tags',
            index: 0,
            data: () => (
              <TagsTable
                status="all"
                tags={tagDirectory}
                onEditRefetch={listTagsByOrgIdRefetch}
              />
            ),
          },
          {
            label: 'User Tags',
            path: '/assessor/',
            index: 1,
            data: () => (
              <TagsTable
                status="user"
                tags={tagDirectory}
                onEditRefetch={listTagsByOrgIdRefetch}
              />
            ),
          },
          {
            label: 'Challenge Tags',
            path: '/assessor/',
            index: 2,
            data: () => (
              <TagsTable
                status="challenge"
                tags={tagDirectory}
                onEditRefetch={listTagsByOrgIdRefetch}
              />
            ),
          },
          {
            label: 'Archived Tags',
            path: '/assessor/',
            index: 3,
            data: () => (
              <TagsTable
                status="archived"
                tags={tagDirectory}
                onEditRefetch={listTagsByOrgIdRefetch}
              />
            ),
          },
        ]}
      />

      <CreateTag
        openModal={createTag}
        tagDetails={initialValues}
        onConfirm={async ({ tagName, tagDescription, tagType, tagColor }) => {
          const duplicateTagName = tagDirectory
            ?.map((tag) => tag?.tagName.toLowerCase())
            ?.includes(tagName.toLowerCase());
          if (duplicateTagName) {
            warningToastMessage('Tag name already exists');
            return;
          }
          // create tag directory
          await createTagDirectoryMutation({
            variables: {
              input: {
                color: tagColor || 'YELLOW',
                name: tagName,
                description: tagDescription,
                status: 'READY',
                type:
                  tagType?.value === TAG_TYPE.USER
                    ? 'USER_TAG'
                    : tagType?.value === TAG_TYPE.CHALLENGE && 'CHALLENGE_TAG',
                userId,
                orgId,
              },
            },
          });
        }}
        setOpenModal={setCreateTag}
        onDismiss={() => {
          warningToastMessage('No tag was created');
        }}
      />
    </Box>
  );
};

export default TagDirectory;
