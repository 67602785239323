import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTextField, FdTypography } from '@fifthdomain/fe-shared';

const SquadDetails = () => {
  const { control } = useFormContext();
  return (
    <Box mb={2}>
      <FdCard variant="outlined">
        <FdTypography variant="h3">Add Squad Details</FdTypography>
        <Box className="flex flex-col mt-2">
          <Box>
            <Controller
              control={control}
              name="squadName"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="squadName"
                    label="Squad Name"
                    required
                    fullWidth
                    error={error}
                    placeholder="Squad name must be less than 50 characters."
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box mt={0} mb={0}>
              <Controller
                control={control}
                name="squadDescription"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mb={2}>
                    <FdTextField
                      id="squadDescription"
                      label="Squad Description"
                      placeholder="Squad description must be less than 80 characters."
                      fullWidth
                      multiline
                      rows={3}
                      error={error}
                      helperText={error && error.message}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Box>
      </FdCard>
    </Box>
  );
};

SquadDetails.propTypes = {
  isEditMode: PropTypes.bool,
  onClickSave: PropTypes.func,
};

SquadDetails.defaultProps = {
  isEditMode: false,
  onClickSave: undefined,
};

export default SquadDetails;
