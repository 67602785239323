import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import {
  FdTypography,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  BasePage,
  FdSkeleton,
  FdButton,
  Authorization,
  PERMISSIONS_AFFILIATED,
} from '@fifthdomain/fe-shared';
import { listSquadsByOrgId } from '../graphql/queries';
import SquadTable from '../components/Squad/SquadTable';
import { deleteSquad } from '../graphql/mutations';
import { successToastMessage } from '../shared/utils/toast';

const Squads = () => {
  const globalSnap = useSnapshot(globalStore);
  const { orgId, permissions, userId } = globalSnap;

  const {
    data: squadsByOrgIdData,
    loading: squadsByOrgIdLoading,
    refetch: refetchSquadsByOrgId,
  } = useQueryRecursive(gql(listSquadsByOrgId), {
    variables: {
      orgId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !orgId,
  });

  const [deleteSquadMutation, { loading: deleteSquadLoading }] = useMutation(
    gql(deleteSquad),
    {
      onCompleted: () => {
        successToastMessage('Success! Squad deleted.');
        // re-query list squads
        refetchSquadsByOrgId();
      },
    },
  );

  const allSquads = (squadsByOrgIdData?.listSquadsByOrgId?.items || []).map(
    (s) => ({
      id: s?.id,
      name: s?.name,
      description: s?.description,
      managers: s?.members?.items?.filter((m) => m?.role === 'MANAGER') || [],
      viewers: s?.members?.items?.filter((m) => m?.role === 'VIEWER') || [],
      members: s?.members?.items?.filter((m) => m?.role === 'MEMBER') || [],
    }),
  );

  const onDeleteSquad = (squad) => {
    deleteSquadMutation({
      variables: {
        input: {
          id: squad?.id,
        },
      },
    });
  };
  const canCreateSquad = Authorization.hasPermission(permissions, [
    PERMISSIONS_AFFILIATED.MANAGE_USERS,
  ]);
  // no filter for manage-users and others will have to be either manager or viewer
  const squadRows = canCreateSquad
    ? allSquads
    : allSquads?.filter(
        (s) =>
          s?.managers?.some((m) => m?.userId === userId) ||
          s?.viewers?.some((m) => m?.userId === userId),
      );

  useEffect(() => {
    if (!canCreateSquad && squadRows?.length === 1) {
      singleSpa.navigateToUrl(
        `/user-management/squads/view/${squadRows[0]?.id}`,
      );
    }
  });

  return (
    <BasePage
      data-cy="squads-page"
      breadCrumbs={[{ url: '/landing/landing-homepage', name: 'Home' }]}
      currentPageBreadcrumbLabel="Squads"
      linkComponent={RouterLink}
      renderBreadCrumbAsButton
    >
      <FdCard variant="outlined">
        <Box className="flex justify-between">
          <FdTypography variant="h3">Squads</FdTypography>
          {canCreateSquad && (
            <FdButton
              onClick={() =>
                singleSpa.navigateToUrl('/user-management/squads/create')
              }
            >
              Create A New Squad
            </FdButton>
          )}
        </Box>
        <Box mt={2}>
          <FdSkeleton loading={squadsByOrgIdLoading} height={643}>
            <SquadTable
              rows={squadRows}
              onDeleteSquad={onDeleteSquad}
              loading={deleteSquadLoading}
              canCreateSquad={canCreateSquad}
            />
          </FdSkeleton>
        </Box>
      </FdCard>
    </BasePage>
  );
};
export default Squads;
