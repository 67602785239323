import Yup from '../Common';

export const initialValues = {
  quizName: '',
  quizDescription: '',
  quizTimeLimit: 1,
  allowMultipleAttempts: false,
  returnResults: false,
  questions: [],
  answers: [],
  options: [],
};

export const validationSchema = Yup.object().shape({
  quizName: Yup.string()
    .required('Please enter a Group Name')
    .max(25, 'Group Name can only have 25 characters')
    .noWhitespace('Please enter a Group Name'),
  quizDescription: Yup.string()
    .max(80, 'Group Description can only have 80 characters')
    .test(
      'noWhitespaceNonRequired',
      'Please enter a valid Group Description',
      (value) => !value || (value && value.trim().length),
    ),
  questions: Yup.array().of(Yup.string()),
});
