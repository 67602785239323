import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import { FdTable, FdModal, FdIcons } from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../shared/utils/toast';

const GroupsTable = ({ rows, onDeleteRow }) => {
  const [groupToDelete, setGroupToDelete] = useState(undefined);
  const columns = [
    { field: 'name', width: 350, headerName: 'Group Name' },
    { field: 'description', width: 550, headerName: 'Description' },
    { field: 'users', width: 150, headerName: 'No of Users' },
    {
      field: 'courses',
      width: 150,
      headerName: 'No of Courses',
    },
  ];
  const { Warning } = FdIcons;

  const actions = [
    {
      label: 'Edit',
      show: true,
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(`/user-management/groups/edit/${id}`);
      },
    },
    {
      label: 'Delete',
      show: true,
      onClick: (row) => setGroupToDelete(row),
    },
  ];

  return (
    <Box height="515px">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows || []}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="user-management-list-groups"
      />
      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <Warning
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Delete Group?</span>
          </Box>
        }
        description="Are you sure you want to delete the Group?"
        confirm="OK"
        dismiss="CANCEL"
        open={groupToDelete}
        onConfirm={() => {
          onDeleteRow(groupToDelete);
          setGroupToDelete(undefined);
        }}
        onDismiss={() => {
          setGroupToDelete(undefined);
          warningToastMessage('Group not deleted');
        }}
      />
    </Box>
  );
};

GroupsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onDeleteRow: PropTypes.func.isRequired,
};

export default GroupsTable;
