import _ from 'lodash';

// object: {}, value: any => returns any
export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

// object: {}, values: [any] => returns [any]
export const getKeysByValues = (object, values) =>
  Object.keys(object).filter((key) => values.includes(object[key]));

// getArrayByLength(3, `SlNo: ${i + 1}`) returns ['SlNo: 1', 'SlNo: 2', 'SlNo: 3']
export const getArrayByLength = (_length, formatFn = (__, i) => i) =>
  Array.from(Array(Number(_length)), formatFn);

export const getValueByKey = (_object, _value) =>
  Object.entries(_object).find(([key]) => key === _value)?.[1];

// call it as [].sort(sortObjectArrayByField('abc','desc'))
export const sortObjectArrayByField = (field, order = 'asc') => {
  const sortOrder = order === 'desc' ? -1 : 1;

  return (a, b) => {
    const valueA = _.get(a, field);
    const valueB = _.get(b, field);

    if (valueA instanceof Date && valueB instanceof Date) {
      // Handle date values
      if (valueA.getTime() < valueB.getTime()) {
        return -1 * sortOrder;
      }
      if (valueA.getTime() > valueB.getTime()) {
        return 1 * sortOrder;
      }
    } else if (typeof valueA === 'string' && typeof valueB === 'string') {
      // Handle string values (case-insensitive)
      const lowerValueA = valueA.toLowerCase();
      const lowerValueB = valueB.toLowerCase();

      if (lowerValueA < lowerValueB) {
        return -1 * sortOrder;
      }
      if (lowerValueA > lowerValueB) {
        return 1 * sortOrder;
      }
    } else {
      // Handle other types
      if (valueA < valueB) {
        return -1 * sortOrder;
      }
      if (valueA > valueB) {
        return 1 * sortOrder;
      }
    }

    return 0;
  };
};

export const arrayToObject = (array) => {
  return array.reduce((acc, item) => {
    const key = Object.keys(item)[0];
    acc[key] = item[key];
    return acc;
  }, {});
};
