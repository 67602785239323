import React from 'react';
import PropTypes from 'prop-types';
import { Box, Slider, withStyles } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import { format } from 'date-fns';

const FdSlider = withStyles(
  (theme) => ({
    root: {
      height: 4,
    },
    thumb: {
      height: 12,
      width: 12,
      marginTop: -4,
      marginLeft: -12,
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + -35px)',
      top: 32,
      '& *': {
        background: 'transparent',
        color: theme?.palette?.type === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)',
        width: 100,
        height: 40,
      },
    },
    track: {
      height: 4,
      borderRadius: 4,
    },
    rail: {
      height: 4,
      borderRadius: 4,
      backgroundColor: 'rgba(189, 189, 189, 1)',
    },
    mark: {
      opacity: 0,
    },
    markActive: {
      opacity: 0,
    },
    markLabel: {
      top: 30,
      '& *': {
        background: 'transparent',
        width: 100,
        height: 40,
        color: 'rgba(0, 0, 0, 0.38)',
      },
    },
    markLabelActive: {
      opacity: 0,
    },
  }),
  { withTheme: true },
)(Slider);

const getWeekOfMonth = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDayOfWeek = firstDayOfMonth.getDay() || 7;
  const dayOfMonth = date.getDate();
  const weekNumber = Math.ceil((dayOfMonth + firstDayOfWeek - 1) / 7);
  return weekNumber;
};

const getDateText = (date) => {
  const week = getWeekOfMonth(date);
  const month = format(date, 'MMM');
  const year = format(date, 'yyyy');
  return { week, month, year };
};

const TimePeriodFilter = ({
  description,
  valueRange,
  onValueChange,
  marks,
  ...props
}) => {
  return (
    <Box height={200}>
      <FdTypography variant="subtitle1">Time Period Filter</FdTypography>
      <Box my={1} minHeight="42px">
        <FdTypography variant="body2" color="secondary">
          {description}
        </FdTypography>
      </Box>
      <Box mx={3}>
        <FdSlider
          value={valueRange}
          onChange={onValueChange}
          size="medium"
          valueLabelDisplay="on"
          marks={marks?.map(({ value, date, first, last }) => {
            const { week, month, year } = getDateText(date);
            return {
              value,
              label:
                first || last ? (
                  <span
                    className="flex flex-col text-center text-xs"
                    style={{
                      marginLeft: first ? '2rem' : 0,
                      marginRight: last ? '2rem' : 0,
                    }}
                  >
                    <span>{`${month} W${week}`}</span>
                    <span>{year}</span>
                  </span>
                ) : (
                  ''
                ),
            };
          })}
          valueLabelFormat={(x) => {
            const mark = marks?.find((m) => x === m?.value);
            const { date, first, last } = mark || {};
            const { week, month, year } = getDateText(date);
            return (
              <span
                className="flex flex-col text-center font-bold"
                style={{
                  marginLeft: first ? '19px' : last ? '-13px' : 0,
                }}
              >
                <span>{`${month} W${week}`}</span>
                <span>{year}</span>
              </span>
            );
          }}
          {...props}
        />
      </Box>
    </Box>
  );
};

TimePeriodFilter.propTypes = {
  description: PropTypes.string,
  valueRange: PropTypes.arrayOf(PropTypes.number),
  onValueChange: PropTypes.func.isRequired,
  marks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

TimePeriodFilter.defaultProps = {
  description: '',
  valueRange: [0, 100],
};

export default TimePeriodFilter;
