import Yup from '../Common';

export const initialValues = {
  groupName: '',
  groupDescription: '',
  users: [],
  courses: [],
  scenarios: [],
};

export const validationSchema = Yup.object().shape({
  groupName: Yup.string()
    .required('Please enter a Group Name')
    .max(25, 'Group Name can only have 25 characters')
    .noWhitespace('Please enter a Group Name'),
  groupDescription: Yup.string()
    .max(80, 'Group Description can only have 80 characters')
    .test(
      'noWhitespaceNonRequired',
      'Please enter a valid Group Description',
      (value) => !value || (value && value.trim().length),
    ),
  users: Yup.array().of(Yup.string()),
  courses: Yup.array().of(Yup.string()),
  scenarios: Yup.array().of(Yup.string()),
});
