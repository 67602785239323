import React, { useState } from 'react';
import PropTypes from 'prop-types';
import singleSpa from 'single-spa';
import { Box } from '@material-ui/core';
import {
  FdTable,
  FdModal,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';
import { warningToastMessage } from '../../shared/utils/toast';

const SquadTable = ({ loading, rows, onDeleteSquad, canCreateSquad }) => {
  const [squadToDelete, setSquadToDelete] = useState(undefined);
  const { userId } = useSnapshot(globalStore);
  const columns = [
    { field: 'name', width: 350, headerName: 'Squad Name' },
    {
      field: 'description',
      width: 200,
      headerName: 'Description',
    },
    {
      field: 'managers',
      width: 250,
      headerName: 'Squad Manager(s)',
      valueGetter: (params) => params.value?.join(', '),
      renderCell: (params) =>
        getCommaSeparatedPlusSuffix(params.row.managers?.map((p) => p)),
    },
    { field: 'viewersCount', width: 350, headerName: 'No. of Squad Viewers' },
    { field: 'membersCount', width: 300, headerName: 'No. of Squad Members' },
  ];

  const actions = [
    {
      label: 'View/Edit',
      show: () => true,
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(`/user-management/squads/view/${id}`);
      },
    },
    {
      label: 'Delete',
      show: (row) => row?.showDelete,
      onClick: (row) => setSquadToDelete(row),
    },
    {
      show: () => false, // dummy entry to display drop down
    },
  ];
  const rowsForDisplay = rows?.map((sr) => ({
    id: sr?.id,
    name: sr?.name,
    description: sr?.description,
    viewersCount: sr?.viewers?.length,
    membersCount: sr?.members?.length,
    managers: sr?.managers?.map((u) => u?.user?.name),
    showDelete: canCreateSquad || sr?.managers?.some((m) => m?.id === userId),
  }));

  return (
    <Box>
      <Box height={643}>
        <FdTable
          toolbarSettings={{
            title: '',
            filterButton: true,
            searchBox: true,
          }}
          rows={rowsForDisplay || []}
          columns={columns}
          tablePageSize={10}
          actions={actions}
          gridId="squads"
          loading={loading}
        />
      </Box>
      <FdModal
        size="md"
        title="Delete Squad?"
        description={
          <>
            Deleting the Squad will delete it from the list of squads in this
            organisation.
            <br />
            The Squad Manager, and Squad Viewer(s) can no longer access this
            squad to view the skills and performance data of the squad. Squad
            Member(s) will no longer see the indication of association with this
            squad on their Home Page.
          </>
        }
        confirm="CANCEL"
        dismiss="DELETE"
        open={squadToDelete}
        showDismissInRed
        onConfirm={() => {
          setSquadToDelete(undefined);
          warningToastMessage('Squad not deleted.');
        }}
        onDismiss={() => {
          onDeleteSquad(squadToDelete);
          setSquadToDelete(undefined);
        }}
      />
    </Box>
  );
};

SquadTable.defaultProps = {
  loading: false,
  canCreateSquad: false,
};

SquadTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onDeleteSquad: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  canCreateSquad: PropTypes.bool,
};

export default SquadTable;
