import Color from 'color';

/* 
  Usage:-
  - add space for drawer - width in pixels
  setAppMarginRightByIds(['topnav'], rightHandSideDrawerWidth = '400px');

  - remove space for drawer - width in pixels
  setAppMarginRightByIds(['topnav'], rightHandSideDrawerWidth = '0');
*/
const setAppMarginRightByIds = (ids, width) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const id in ids) {
    if (document.getElementById(ids[id])) {
      document.getElementById(ids[id]).style.marginRight = width;
    }
  }
  if (document.getElementsByTagName('header')[0]) {
    document.getElementsByTagName('header')[0].style.paddingRight = width;
  }
};

export const getLabelColor = (color = '#FFF') => {
  return Color(color).isLight() ? 'black' : 'white';
};

export default setAppMarginRightByIds;
