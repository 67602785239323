/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const attempt = /* GraphQL */ `
  mutation Attempt($taskId: ID!, $userAssessmentId: ID!, $answer: String!) {
    attempt(
      taskId: $taskId
      userAssessmentId: $userAssessmentId
      answer: $answer
    )
  }
`;
export const startAssessmentForUser = /* GraphQL */ `
  mutation StartAssessmentForUser($userAssessmentId: ID!) {
    startAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;
export const stopAssessmentForUser = /* GraphQL */ `
  mutation StopAssessmentForUser($userAssessmentId: ID!) {
    stopAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;
export const createNewAssessment = /* GraphQL */ `
  mutation CreateNewAssessment($assesssment: NewAssessment!) {
    createNewAssessment(assesssment: $assesssment)
  }
`;
export const editAssessment = /* GraphQL */ `
  mutation EditAssessment($assesssment: EditAssessment!) {
    editAssessment(assesssment: $assesssment)
  }
`;
export const startGroupAssessment = /* GraphQL */ `
  mutation StartGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    startGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;
export const stopGroupAssessment = /* GraphQL */ `
  mutation StopGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    stopGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;
export const addUsers = /* GraphQL */ `
  mutation AddUsers(
    $emails: [AWSEmail]
    $orgId: ID!
    $type: USER_TYPE
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
    $groups: [ID]
    $action: INVITATION_TYPE
    $affliationAction: AFFLIATION_REQUEST_ACTION
  ) {
    addUsers(
      emails: $emails
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
      groups: $groups
      action: $action
      affliationAction: $affliationAction
    )
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser(
    $email: String!
    $orgId: ID!
    $type: USER_TYPE!
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
  ) {
    addUser(
      email: $email
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
    )
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: EditUser!) {
    updateUser(input: $input)
  }
`;
export const removeUserFromAssessment = /* GraphQL */ `
  mutation RemoveUserFromAssessment($userAssessmentId: ID!) {
    removeUserFromAssessment(userAssessmentId: $userAssessmentId)
  }
`;
export const removeInvitedUser = /* GraphQL */ `
  mutation RemoveInvitedUser($inviteToken: ID!) {
    removeInvitedUser(inviteToken: $inviteToken)
  }
`;
export const createNewOnboarding = /* GraphQL */ `
  mutation CreateNewOnboarding($input: CreateOnboardingInput!) {
    createNewOnboarding(input: $input)
  }
`;
export const updateRoleAndPermissions = /* GraphQL */ `
  mutation UpdateRoleAndPermissions(
    $emails: [AWSEmail]!
    $permissions: [PERMISSION_TYPE]
  ) {
    updateRoleAndPermissions(
      emails: $emails
      permissions: $permissions
    )
  }
`;
export const manageUserAndGroup = /* GraphQL */ `
  mutation ManageUserAndGroup(
    $groupId: ID!
    $userIds: [ID!]
    $operation: OPERATION_TYPE!
  ) {
    manageUserAndGroup(
      groupId: $groupId
      userIds: $userIds
      operation: $operation
    )
  }
`;
export const removeUserFromOrg = /* GraphQL */ `
  mutation RemoveUserFromOrg($email: AWSEmail!) {
    removeUserFromOrg(email: $email)
  }
`;
export const removeGroupsFromAssessment = /* GraphQL */ `
  mutation RemoveGroupsFromAssessment($groupIds: [ID]!, $assessmentId: ID!) {
    removeGroupsFromAssessment(groupIds: $groupIds, assessmentId: $assessmentId)
  }
`;
export const removeGroups = /* GraphQL */ `
  mutation RemoveGroups($groupIds: [ID]!) {
    removeGroups(groupIds: $groupIds)
  }
`;
export const migrateParticipant = /* GraphQL */ `
  mutation MigrateParticipant($users: [MigratedParticipant]) {
    migrateParticipant(users: $users)
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment(
    $input: DeleteAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    deleteAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHint = /* GraphQL */ `
  mutation CreateHint(
    $input: CreateHintInput!
    $condition: ModelHintConditionInput
  ) {
    createHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateHint = /* GraphQL */ `
  mutation UpdateHint(
    $input: UpdateHintInput!
    $condition: ModelHintConditionInput
  ) {
    updateHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteHint = /* GraphQL */ `
  mutation DeleteHint(
    $input: DeleteHintInput!
    $condition: ModelHintConditionInput
  ) {
    deleteHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const createTaskAssessment = /* GraphQL */ `
  mutation CreateTaskAssessment(
    $input: CreateTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    createTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskAssessment = /* GraphQL */ `
  mutation UpdateTaskAssessment(
    $input: UpdateTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    updateTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskAssessment = /* GraphQL */ `
  mutation DeleteTaskAssessment(
    $input: DeleteTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    deleteTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTaskAttempt = /* GraphQL */ `
  mutation CreateTaskAttempt(
    $input: CreateTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    createTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const updateTaskAttempt = /* GraphQL */ `
  mutation UpdateTaskAttempt(
    $input: UpdateTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    updateTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const deleteTaskAttempt = /* GraphQL */ `
  mutation DeleteTaskAttempt(
    $input: DeleteTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    deleteTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const createTaskOpened = /* GraphQL */ `
  mutation CreateTaskOpened(
    $input: CreateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    createTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const updateTaskOpened = /* GraphQL */ `
  mutation UpdateTaskOpened(
    $input: UpdateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    updateTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const deleteTaskOpened = /* GraphQL */ `
  mutation DeleteTaskOpened(
    $input: DeleteTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    deleteTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const createCompetency = /* GraphQL */ `
  mutation CreateCompetency(
    $input: CreateCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    createCompetency(input: $input, condition: $condition) {
      id
      area
      topic
      competency
      code
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetency = /* GraphQL */ `
  mutation UpdateCompetency(
    $input: UpdateCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    updateCompetency(input: $input, condition: $condition) {
      id
      area
      topic
      competency
      code
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetency = /* GraphQL */ `
  mutation DeleteCompetency(
    $input: DeleteCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    deleteCompetency(input: $input, condition: $condition) {
      id
      area
      topic
      competency
      code
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCompetencyTask = /* GraphQL */ `
  mutation CreateCompetencyTask(
    $input: CreateCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    createCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      competency {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetencyTask = /* GraphQL */ `
  mutation UpdateCompetencyTask(
    $input: UpdateCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    updateCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      competency {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetencyTask = /* GraphQL */ `
  mutation DeleteCompetencyTask(
    $input: DeleteCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    deleteCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      competency {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrg = /* GraphQL */ `
  mutation CreateOrg(
    $input: CreateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    createOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrg = /* GraphQL */ `
  mutation UpdateOrg(
    $input: UpdateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    updateOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrg = /* GraphQL */ `
  mutation DeleteOrg(
    $input: DeleteOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    deleteOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserConsent = /* GraphQL */ `
  mutation CreateUserConsent(
    $input: CreateUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    createUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateUserConsent = /* GraphQL */ `
  mutation UpdateUserConsent(
    $input: UpdateUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    updateUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserConsent = /* GraphQL */ `
  mutation DeleteUserConsent(
    $input: DeleteUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    deleteUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const createConsent = /* GraphQL */ `
  mutation CreateConsent(
    $input: CreateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    createConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateConsent = /* GraphQL */ `
  mutation UpdateConsent(
    $input: UpdateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    updateConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsent = /* GraphQL */ `
  mutation DeleteConsent(
    $input: DeleteConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    deleteConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      description
      orgId
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupAssessment = /* GraphQL */ `
  mutation CreateGroupAssessment(
    $input: CreateGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    createGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupAssessment = /* GraphQL */ `
  mutation UpdateGroupAssessment(
    $input: UpdateGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    updateGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupAssessment = /* GraphQL */ `
  mutation DeleteGroupAssessment(
    $input: DeleteGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    deleteGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz(
    $input: CreateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    createQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz(
    $input: UpdateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    updateQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuiz = /* GraphQL */ `
  mutation DeleteQuiz(
    $input: DeleteQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    deleteQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionOption = /* GraphQL */ `
  mutation CreateQuestionOption(
    $input: CreateQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    createQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionOption = /* GraphQL */ `
  mutation UpdateQuestionOption(
    $input: UpdateQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    updateQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionOption = /* GraphQL */ `
  mutation DeleteQuestionOption(
    $input: DeleteQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    deleteQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionAnswer = /* GraphQL */ `
  mutation CreateQuestionAnswer(
    $input: CreateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    createQuestionAnswer(input: $input, condition: $condition) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionAnswer = /* GraphQL */ `
  mutation UpdateQuestionAnswer(
    $input: UpdateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    updateQuestionAnswer(input: $input, condition: $condition) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionAnswer = /* GraphQL */ `
  mutation DeleteQuestionAnswer(
    $input: DeleteQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    deleteQuestionAnswer(input: $input, condition: $condition) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const createCompetencyQuestions = /* GraphQL */ `
  mutation CreateCompetencyQuestions(
    $input: CreateCompetencyQuestionsInput!
    $condition: ModelCompetencyQuestionsConditionInput
  ) {
    createCompetencyQuestions(input: $input, condition: $condition) {
      id
      competencyID
      questionID
      competency {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetencyQuestions = /* GraphQL */ `
  mutation UpdateCompetencyQuestions(
    $input: UpdateCompetencyQuestionsInput!
    $condition: ModelCompetencyQuestionsConditionInput
  ) {
    updateCompetencyQuestions(input: $input, condition: $condition) {
      id
      competencyID
      questionID
      competency {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetencyQuestions = /* GraphQL */ `
  mutation DeleteCompetencyQuestions(
    $input: DeleteCompetencyQuestionsInput!
    $condition: ModelCompetencyQuestionsConditionInput
  ) {
    deleteCompetencyQuestions(input: $input, condition: $condition) {
      id
      competencyID
      questionID
      competency {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      description
      type
      color
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          alias
          maxInvitees
          remainingInvitees
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      description
      type
      color
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          alias
          maxInvitees
          remainingInvitees
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      description
      type
      color
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          alias
          maxInvitees
          remainingInvitees
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const updateUserAffliation = /* GraphQL */ `
  mutation UpdateUserAffliation(
    $userId: ID
    $email: AWSEmail!
    $orgId: ID!
    $affiliationAction: AFFLIATION_REQUEST_ACTION
  ) {
    updateUserAffliation(
      userId: $userId
      email: $email
      orgId: $orgId
      affiliationAction: $affiliationAction
    ) {
      orgName
      status
      __typename
    }
  }
`;
export const createSquad = /* GraphQL */ `
  mutation CreateSquad(
    $input: CreateSquadInput!
    $condition: ModelSquadConditionInput
  ) {
    createSquad(input: $input, condition: $condition) {
      id
      name
      description
    }
  }
`;
export const updateSquad = /* GraphQL */ `
  mutation UpdateSquad(
    $input: UpdateSquadInput!
    $condition: ModelSquadConditionInput
  ) {
    updateSquad(input: $input, condition: $condition) {
      id
      name
      description
      orgId
    }
  }
`;
export const deleteSquad = /* GraphQL */ `
  mutation DeleteSquad(
    $input: DeleteSquadInput!
    $condition: ModelSquadConditionInput
  ) {
    deleteSquad(input: $input, condition: $condition) {
      id
      name
      orgId
    }
  }
`;
export const createSquadMember = /* GraphQL */ `
  mutation CreateSquadMember(
    $input: CreateSquadMemberInput!
    $condition: ModelSquadMemberConditionInput
  ) {
    createSquadMember(input: $input, condition: $condition) {
      id
      squadId
      squad {
        id
        name
        description
        orgId
      }
      userId
      role
    }
  }
`;
export const updateSquadMember = /* GraphQL */ `
  mutation UpdateSquadMember(
    $input: UpdateSquadMemberInput!
    $condition: ModelSquadMemberConditionInput
  ) {
    updateSquadMember(input: $input, condition: $condition) {
      id
      squadId
      squad {
        id
        name
        description
        orgId
      }
      userId
      role
    }
  }
`;
export const deleteSquadMember = /* GraphQL */ `
  mutation DeleteSquadMember(
    $input: DeleteSquadMemberInput!
    $condition: ModelSquadMemberConditionInput
  ) {
    deleteSquadMember(input: $input, condition: $condition) {
      id
      squadId
      squad {
        id
        name
        description
        orgId
      }
      userId
      role
    }
  }
`;
