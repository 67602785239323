import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography, FdButton, FdCard } from '@fifthdomain/fe-shared';

const EditContainer = ({
  title,
  labelOnly,
  children,
  hideEdit,
  field,
  onCancel,
  onSave,
  error,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Box mb={1}>
      <FdCard variant="outlined">
        <Box className="flex items-center justify-between mb-2">
          <Box className="flex items-center">
            <FdTypography variant="body1" style={{ fontWeight: '500' }}>
              {title}
            </FdTypography>
          </Box>
          {editMode ? (
            <Box className="flex space-x-2">
              <FdButton
                variant="secondary"
                onClick={() => {
                  setEditMode(false);
                  onCancel(field);
                }}
              >
                Cancel
              </FdButton>
              <FdButton
                variant="primary"
                onClick={() => {
                  if (!error) {
                    setEditMode(false);
                    onSave(field);
                  }
                }}
                disabled={error}
              >
                Save
              </FdButton>
            </Box>
          ) : (
            !hideEdit && (
              <FdButton
                variant="primary"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edit
              </FdButton>
            )
          )}
        </Box>
        {editMode ? children : labelOnly}
      </FdCard>
    </Box>
  );
};

EditContainer.propTypes = {
  title: PropTypes.string.isRequired,
  labelOnly: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideEdit: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default EditContainer;
