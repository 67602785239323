import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import SquadUserCard from './SquadUserCard';

const SquadUsers = ({ allUsers, allSquadUsers }) => {
  return (
    <Box className="flex flex-col">
      <SquadUserCard
        heading="Assign Squad Manager(s)"
        description="Squad Manager holds the power to add or remove Squad Member(s) and Squad Viewer(s) to a squad,  and  to view squad’s skill and performance data. Their own skills also contribute to the skills of the squad. Affiliated users can be Squad Managers for only one squad at a time."
        actionButtonLabel="Assign SQUAD manager(s)"
        modalOptions={{
          heading: 'Assign Squad Manager(s)',
          description:
            "Every squad created must have at least one Squad Manager assigned mandatorily. Squad Managers are chosen from the pool of affiliated users within an organisation with Manage Squad permission, and there's no upper limit to how many Squad Managers a squad can have.",
          selectHeading:
            'Select Squad Managers(s) from list of Affiliated Users:',
          errorMessage: 'Please select at least one Squad Manager.',
        }}
        toastLabel="Squad Manager(s)"
        allUsers={allUsers}
        allSquadUsers={allSquadUsers}
        type="managers"
      />
      <SquadUserCard
        heading="Add Squad Viewer(s)"
        description="Squad Viewer holds the power to view squad's skill and performance data; however, their own skills do not contribute to the skills of the squad. Affiliated users can be Squad Viewers for multiple squads at a time."
        actionButtonLabel="Add SQUAD viewer(s)"
        modalOptions={{
          heading: 'Add Squad Viewer(s)',
          description:
            'The Squad Viewers can be selected from a list of all affiliated users within an organisation, who have Manage Users, Manage Squad and/or View Insights permission.',
          selectHeading:
            'Select Squad Viewer(s) from list of Affiliated Users:',
          errorMessage: 'Please select at least one Squad Viewer to continue.',
        }}
        toastLabel="Squad Viewer(s)"
        allUsers={allUsers}
        allSquadUsers={allSquadUsers}
        type="viewers"
        optional
      />
      <SquadUserCard
        heading="Add Squad Member(s)"
        description="The skills of a Squad Member contribute to the skills and performance metrics of the squad. Affiliated users are limited to membership in a single squad within the organisation at any given moment. They will notice a marker on their Home Page signifying their squad association, but their access to squad details stops there."
        actionButtonLabel="Add SQUAD member(s)"
        modalOptions={{
          heading: 'Add Squad Member(s)',
          description: '',
          selectHeading: 'Select Squad Members from list of Affiliated Users:',
          errorMessage: 'Please select at least one Squad Member to continue.',
        }}
        toastLabel="Squad Member(s)"
        allUsers={allUsers}
        allSquadUsers={allSquadUsers}
        type="members"
        optional
      />
    </Box>
  );
};

SquadUsers.propTypes = {
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allSquadUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SquadUsers;
