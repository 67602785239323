import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdTextField,
  FdButton,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../shared/utils/toast';

const GroupDetails = ({ isEditMode, onClickSave }) => {
  const [editInProgress, setEditInProgress] = useState(false);
  const { getValues, control, reset, trigger } = useFormContext();
  return (
    <FdCard
      heading={
        isEditMode ? (
          <Box display="flex" justifyContent="space-between">
            Group Details
            <Box display="flex" justifyContent="space-between">
              {editInProgress ? (
                <Box display="flex">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditInProgress(false);
                        warningToastMessage('Changes to group not saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        if (await trigger(['groupName', 'groupDescription'])) {
                          setEditInProgress(false);
                          onClickSave?.();
                        }
                      }}
                    >
                      Save
                    </FdButton>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditInProgress(true)}
                  >
                    Edit
                  </FdButton>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          'Add Group Details'
        )
      }
    >
      <Box display="flex" flexDirection="column">
        {!isEditMode || (isEditMode && editInProgress) ? (
          <Box>
            <Controller
              control={control}
              name="groupName"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="groupName"
                    label="Group Name"
                    required
                    fullWidth
                    error={error}
                    placeholder="Group name must be less than 25 characters."
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box mt={0} mb={0}>
              <Controller
                control={control}
                name="groupDescription"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mb={2}>
                    <FdTextField
                      id="groupDescription"
                      label="Group Description"
                      placeholder="Group description must be less than 80 characters."
                      fullWidth
                      multiline
                      rows={3}
                      error={error}
                      helperText={error && error.message}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
            </Box>
          </Box>
        ) : (
          <Box style={{ whiteSpace: 'pre-wrap' }}>
            <Box mt={2}>
              <FdTypography variant="subtitle1">Group Name</FdTypography>
              <FdTypography variant="body1" color="secondary">
                {getValues('groupName')}
              </FdTypography>
            </Box>
            <Box mt={2}>
              <FdTypography variant="subtitle1">Group Description</FdTypography>
              <FdTypography variant="body1" color="secondary">
                {getValues('groupDescription')}
              </FdTypography>
            </Box>
          </Box>
        )}
      </Box>
    </FdCard>
  );
};

GroupDetails.propTypes = {
  isEditMode: PropTypes.bool,
  onClickSave: PropTypes.func,
};

GroupDetails.defaultProps = {
  isEditMode: false,
  onClickSave: undefined,
};

export default GroupDetails;
