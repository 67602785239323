import React, { useState } from 'react';
import { Box, Checkbox, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  FdTypography,
  FdButton,
  FdIcons,
  FdRadioGroup,
  FdTextField,
} from '@fifthdomain/fe-shared';
import { DUMMY_DATA } from '../../constants';
import AddQuizQuestions from './AddQuizQuestions';

const useStyles = makeStyles(() => ({
  customRadioGroup: {
    color: 'rgba(0, 0, 0, 0.54)',
    '& label': {
      marginBottom: 15,
    },
  },
}));

const QuizQuestions = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { AvTimer, DragIndicator } = FdIcons;
  const { quizId } = useParams();
  const classes = useStyles();

  let quiz = DUMMY_DATA.find((quiz) => quiz.id === quizId);
  return (
    <Box p={3} bgcolor="#fff">
      <Box display="flex" justifyContent="space-between">
        <FdTypography variant="h3">View '{quiz.name}'</FdTypography>
        <Box display="flex">
          <AvTimer />
          <Box ml={2}>
            <FdTypography>{quiz.time}</FdTypography>
          </Box>
          <Box ml={4}>
            <FdTypography>Total Ponits: {quiz.points}</FdTypography>
          </Box>
          <Box ml={4}>
            {isEdit ? (
              <Box display="flex">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  >
                    Save
                  </FdButton>
                </Box>
              </Box>
            ) : (
              <FdButton
                size="small"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                Edit
              </FdButton>
            )}
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        {isEdit ? (
          <AddQuizQuestions isEdit={isEdit} allQuestions={quiz?.questions} />
        ) : (
          quiz?.questions.map((question, index) => (
            <Box
              sx={{ p: 2, border: '1px solid #E0E0E0' }}
              mb={2}
              key={question.id}
            >
              <Box display="flex" justifyContent="space-between">
                <FdTypography variant="h3">0{index + 1}</FdTypography>
                <Box ml={4} mr={3} display="flex">
                  <FdTypography>Points</FdTypography>
                  <Box ml={4}>
                    <FdTypography> {question.points}</FdTypography>
                  </Box>
                </Box>
              </Box>
              <Box mt={3}>
                <FdTypography>{question.question}</FdTypography>
                <Box mt={2} mb={2}>
                  <FdTypography variant="body1" color="secondary">
                    {question.subtitle}
                  </FdTypography>
                </Box>
                {question.type === 'MULTIPLE_CHOICE' && (
                  <Box>
                    {question.answers.map((answer) => (
                      <Box
                        key={answer.id}
                        display="flex"
                        alignItems="center"
                        mb={1}
                      >
                        <Checkbox />
                        <FdTypography variant="body1" color="secondary">
                          {answer.label}
                        </FdTypography>
                      </Box>
                    ))}
                  </Box>
                )}
                {question.type === 'SINGLE_CHOICE' && (
                  <Box ml={2} className={classes.customRadioGroup}>
                    <FdRadioGroup
                      id="questions-option"
                      options={question.answers.map((answer) => answer.label)}
                      fullWidth
                      onChange={() => {}}
                      defaultValue={
                        question.answers.map((answer) => answer.label)[0]
                      }
                    />
                  </Box>
                )}
                {question.type === 'ORDERING' && (
                  <Box>
                    {question.answers.map((answer) => (
                      <Box
                        key={answer.id}
                        display="flex"
                        alignItems="center"
                        mb={3}
                      >
                        <DragIndicator />
                        <Box ml={2}>
                          <FdTypography variant="body1" color="secondary">
                            {answer.label}
                          </FdTypography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                {question.type === 'FREE_TEXT' && (
                  <Box>
                    <FdTextField
                      id="time-limit"
                      placeholder="Write your answer here."
                      multiline
                      required
                      type="number"
                      rows={4}
                      fullWidth
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default QuizQuestions;
