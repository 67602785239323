import React from 'react';

const TrainingsIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="4" fill="#B3E5FC" />
      <path
        opacity="0.85"
        d="M9.72086 10.66L8.77975 11.11L6.83372 9.895L7.3601 8.875C7.46644 8.675 7.6233 8.5125 7.83066 8.3875C8.03802 8.2625 8.264 8.2 8.50858 8.2H9.36993V4.6C9.36993 4.43 9.43108 4.2875 9.55337 4.1725C9.67566 4.0575 9.8272 4 10.008 4H13.3258C13.4428 4 13.5332 4.0475 13.597 4.1425C13.6608 4.2375 13.6661 4.335 13.6129 4.435L13.262 5.065C13.2407 5.115 13.2301 5.16 13.2301 5.2C13.2301 5.24 13.2407 5.285 13.262 5.335L13.6129 5.965C13.6661 6.065 13.6608 6.1625 13.597 6.2575C13.5332 6.3525 13.4428 6.4 13.3258 6.4H10.646V8.2H11.4436C11.6882 8.2 11.9115 8.26 12.1135 8.38C12.3156 8.5 12.4751 8.66 12.592 8.86L13.1503 9.91L11.2362 11.11L10.2951 10.66C10.21 10.61 10.1143 10.585 10.008 10.585C9.90163 10.585 9.80593 10.61 9.72086 10.66ZM4.07419 15.13L6.25948 10.975L8.38097 12.295C8.47668 12.355 8.58036 12.39 8.69202 12.4C8.80367 12.41 8.91267 12.39 9.01901 12.34L10.008 11.875L10.9969 12.34C11.1033 12.39 11.2123 12.4125 11.3239 12.4075C11.4356 12.4025 11.5393 12.37 11.635 12.31L13.7246 11.005L15.9258 15.13C16.0321 15.33 16.0242 15.525 15.9019 15.715C15.7796 15.905 15.5962 16 15.3516 16H4.64843C4.40385 16 4.22041 15.905 4.09812 15.715C3.97583 15.525 3.96785 15.33 4.07419 15.13Z"
        fill="black"
        fillOpacity="0.87"
      />
    </svg>
  );
};

export default TrainingsIcon;
