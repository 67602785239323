export const USER_TYPE = Object.freeze({
  REGISTERED: 'Registered',
  INVITED: 'Invited',
  MANAGER: 'MANAGER',
  ADMIN: 'ADMIN',
  PARTICIPANT: 'Participant',
  USER_TITLE_INVITE: 'Invite New Users',
  USER_TITLE_DELETE: 'Delete User',
});

export const USER_ROLES = ['Admin', 'Manager', 'Participant'];
export const USER_ROLES_TAB_VALUES = {
  0: 'Participants',
  1: 'Admins/Managers',
};

export const USER_PERMISSIONS = [
  {
    id: 'CREATE',
    value: 'Create',
  },
  {
    id: 'MANAGE_TRAINING',
    value: 'Manage Trainings',
  },
  {
    id: 'MANAGE_GROUPS',
    value: 'Manage Groups',
  },
  {
    id: 'TEST_CONTENT',
    value: 'Test Content',
  },
  {
    id: 'MANAGE_PARTICIPANTS',
    value: 'Manage Participants',
  },
  {
    id: 'VIEW_INSIGHTS',
    value: 'View Insights',
  },
  {
    id: 'MANAGE_MANAGERS',
    value: 'Manage Managers',
  },
  {
    id: 'MANAGE_TAGS',
    value: 'Manage Tags',
  },
];

export const USER_PERMISSION_TYPE = Object.freeze({
  MANAGE_GROUPS: 'MANAGE_GROUPS',
  MANAGE_QUIZ: 'MANAGE_QUIZ',
  MANAGE_TAGS: 'MANAGE_TAGS',
});

export const DUMMY_DATA = [
  {
    id: '1',
    name: 'QUIZ 01',
    description: 'Quiz Descrition',
    creator: 'Ravi Chandra',
    attempts: 5,
    course: 5,
    date: 'dd/m/yyy',
    time: '02:30:00',
    points: 1,
    questions: [
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the checkboxes to select the correct answer(s). It is multiple choice question.',
        points: 1,
        type: 'MULTIPLE_CHOICE',
        answers: [
          {
            id: '1',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
            correct: false,
          },
          {
            id: '2',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
            correct: false,
          },
          {
            id: '3',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
            correct: true,
          },
          {
            id: '4',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
            correct: true,
          },
          {
            id: '5',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
            correct: false,
          },
        ],
      },
      {
        id: '2',
        question: 'Your questions goes here?',
        subtitle:
          'Use the radio to select the correct answer. It is single choice question.',
        points: 1,
        type: 'SINGLE_CHOICE',
        answers: [
          {
            id: '1',
            label:
              '1 Choices for the questions. Only one option is right. Select appropriately.',
            correct: false,
          },
          {
            id: '2',
            label:
              '2 Choices for the questions. Only one option is right. Select appropriately.',
            correct: false,
          },
          {
            id: '3',
            label:
              '3 Choices for the questions. Only one option is right. Select appropriately.',
            correct: true,
          },
          {
            id: '4',
            label:
              '4 Choices for the questions. Only one option is right. Select appropriately.',
            correct: false,
          },
          {
            id: '5',
            label:
              '5 Choices for the questions. Only one option is right. Select appropriately.',
            correct: false,
          },
        ],
      },
      {
        id: '3',
        question: 'Your questions goes here?',
        subtitle: 'Instructions for the choice of the question goes here.',
        points: 3,
        type: 'ORDERING',
        answers: [
          {
            id: '1',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '2',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '3',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '4',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '5',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
        ],
      },
      {
        id: '4',
        question: 'Your questions goes here?',
        subtitle:
          'Long long essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'FREE_TEXT',
        points: 3,
      },
      {
        id: '5',
        question: 'Your questions goes here?',
        subtitle:
          'Short essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'FREE_TEXT',
        points: 3,
      },
    ],
  },
  {
    id: '2',
    name: 'QUIZ 02',
    description: 'Quiz Descrition',
    creator: 'Elon Musk',
    attempts: 5,
    course: 5,
    date: 'dd/m/yyy',
    time: '02:30:00',
    points: 1,
    questions: [
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the checkboxes to select the correct answer(s). It is multiple choice question.',
        points: 1,
        type: 'checkbox',
        answers: [
          {
            id: '1',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '2',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '3',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '4',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '5',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
        ],
      },
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the radio to select the correct answer. It is single choice question.',
        points: 1,
        type: 'radio',
        answers: [
          {
            id: '1',
            label:
              '1 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '2',
            label:
              '2 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '3',
            label:
              '3 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '4',
            label:
              '4 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '5',
            label:
              '5 Choices for the questions. Only one option is right. Select appropriately.',
          },
        ],
      },
      {
        id: '2',
        question: 'Your questions goes here?',
        subtitle: 'Instructions for the choice of the question goes here.',
        points: 3,
        type: 'dragndrop',
        answers: [
          {
            id: '1',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '2',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '3',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '4',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '5',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
        ],
      },
      {
        id: '3',
        question: 'Your questions goes here?',
        subtitle:
          'Long long essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
      {
        id: '4',
        question: 'Your questions goes here?',
        subtitle:
          'Short essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
    ],
  },
  {
    id: '2',
    name: 'QUIZ 03',
    description: 'Quiz Descrition',
    creator: 'Steve Jobs',
    attempts: 10,
    course: 10,
    date: 'dd/m/yyy',
    time: '02:30:00',
    points: 1,
    questions: [
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the checkboxes to select the correct answer(s). It is multiple choice question.',
        points: 1,
        type: 'checkbox',
        answers: [
          {
            id: '1',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '2',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '3',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '4',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '5',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
        ],
      },
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the radio to select the correct answer. It is single choice question.',
        points: 1,
        type: 'radio',
        answers: [
          {
            id: '1',
            label:
              '1 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '2',
            label:
              '2 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '3',
            label:
              '3 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '4',
            label:
              '4 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '5',
            label:
              '5 Choices for the questions. Only one option is right. Select appropriately.',
          },
        ],
      },
      {
        id: '2',
        question: 'Your questions goes here?',
        subtitle: 'Instructions for the choice of the question goes here.',
        points: 3,
        type: 'dragndrop',
        answers: [
          {
            id: '1',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '2',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '3',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '4',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '5',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
        ],
      },
      {
        id: '3',
        question: 'Your questions goes here?',
        subtitle:
          'Long long essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
      {
        id: '4',
        question: 'Your questions goes here?',
        subtitle:
          'Short essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
    ],
  },
  {
    id: '3',
    name: 'QUIZ 04',
    description: 'Quiz Descrition',
    creator: 'Amitabh Bachan',
    attempts: 6,
    course: 6,
    date: 'dd/m/yyy',
    time: '02:30:00',
    points: 1,
    questions: [
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the checkboxes to select the correct answer(s). It is multiple choice question.',
        points: 1,
        type: 'checkbox',
        answers: [
          {
            id: '1',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '2',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '3',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '4',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '5',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
        ],
      },
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the radio to select the correct answer. It is single choice question.',
        points: 1,
        type: 'radio',
        answers: [
          {
            id: '1',
            label:
              '1 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '2',
            label:
              '2 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '3',
            label:
              '3 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '4',
            label:
              '4 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '5',
            label:
              '5 Choices for the questions. Only one option is right. Select appropriately.',
          },
        ],
      },
      {
        id: '2',
        question: 'Your questions goes here?',
        subtitle: 'Instructions for the choice of the question goes here.',
        points: 3,
        type: 'dragndrop',
        answers: [
          {
            id: '1',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '2',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '3',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '4',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '5',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
        ],
      },
      {
        id: '3',
        question: 'Your questions goes here?',
        subtitle:
          'Long long essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
      {
        id: '4',
        question: 'Your questions goes here?',
        subtitle:
          'Short essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
    ],
  },
  {
    id: '4',
    name: 'QUIZ 05',
    description: 'Quiz Descrition',
    creator: 'Whitney',
    attempts: 2,
    course: 2,
    date: 'dd/m/yyy',
    time: '02:30:00',
    points: 1,
    questions: [
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the checkboxes to select the correct answer(s). It is multiple choice question.',
        points: 1,
        type: 'checkbox',
        answers: [
          {
            id: '1',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '2',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '3',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '4',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
          {
            id: '5',
            label:
              'Choices for the questions. One or more are correct. Select appropriately.',
          },
        ],
      },
      {
        id: '1',
        question: 'Your questions goes here?',
        subtitle:
          'Use the radio to select the correct answer. It is single choice question.',
        points: 1,
        type: 'radio',
        answers: [
          {
            id: '1',
            label:
              '1 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '2',
            label:
              '2 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '3',
            label:
              '3 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '4',
            label:
              '4 Choices for the questions. Only one option is right. Select appropriately.',
          },
          {
            id: '5',
            label:
              '5 Choices for the questions. Only one option is right. Select appropriately.',
          },
        ],
      },
      {
        id: '2',
        question: 'Your questions goes here?',
        subtitle: 'Instructions for the choice of the question goes here.',
        points: 3,
        type: 'dragndrop',
        answers: [
          {
            id: '1',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '2',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '3',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '4',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
          {
            id: '5',
            label:
              'Order the choices correctly or put it in right logical order by moving them.',
          },
        ],
      },
      {
        id: '3',
        question: 'Your questions goes here?',
        subtitle:
          'Long long essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
      {
        id: '4',
        question: 'Your questions goes here?',
        subtitle:
          'Short essay type of answer and explain it here whatever makes sense to your questions.',
        type: 'textarea',
        points: 3,
      },
    ],
  },
];

export const THEMES = Object.freeze([
  { value: 'LIGHT', label: 'Light Theme' },
  { value: 'RETRO', label: 'Retro Theme' },
]);

export const TAG_TYPE = Object.freeze({
  CHALLENGE: 'Challenge Tag',
  USER: 'User Tag',
});

export const TAG_COLOUR = [
  {
    backgroundColor: '#FFE082',
    value: 'YELLOW',
  },
  {
    backgroundColor: '#FFAB91',
    value: 'ORANGE',
  },
  {
    backgroundColor: '#F48FB1',
    value: 'PINK',
  },
  {
    backgroundColor: '#CE93D8',
    value: 'PURPLE',
  },
  {
    backgroundColor: '#40C4FF',
    value: 'BLUE',
  },
  {
    backgroundColor: '#80CBC4',
    value: 'TEAL',
  },
];

export const PROFICIENCY = {
  Novice: {
    color: '#BAE8D2',
    range: '1',
    completeColor: '#76D1A5',
  },
  'Advanced Beginner': {
    color: '#CBD9FF',
    range: '2',
    completeColor: '#97B4FF',
  },
  Competent: {
    color: '#FADCB8',
    range: '3',
    completeColor: '#F6B971',
  },
  Proficient: {
    color: '#F0BFDD',
    range: '4',
    completeColor: '#E380BB',
  },
  Expert: {
    color: '#EAA9A9',
    range: '5',
    completeColor: '#D55353',
  },
};

export const PROFICIENCY_LEVELS = {
  1: 'Novice',
  2: 'Advanced Beginner',
  3: 'Competent',
  4: 'Proficient',
  5: 'Expert',
};

// skills performance timeline data starts from this date - week 1 of October 2023
export const SKILLS_PERFORMANCE_TIMELINE_START_DATE = new Date(2023, 9, 1);
