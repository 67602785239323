import { EMAIL_REGEX } from '../../shared/utils/emailUtil';
import Yup from '../Common';

export const initialValues = {
  emails: [],
  permissions: [],
  groups: [],
  confirm1: false,
  confirm2: false,
};

export const validationSchema = Yup.object().shape({
  emails: Yup.array()
    .of(
      Yup.string().matches(
        EMAIL_REGEX,
        'One or more of the email addresses are invalid',
      ),
    )
    .min(1, 'Please enter an email before sending an invite.'),
  permissions: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.string()),
  confirm1: Yup.boolean().oneOf([true]).required(),
  confirm2: Yup.boolean().oneOf([true]).required(),
});
