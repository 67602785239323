import React from 'react';
import { Box } from '@material-ui/core';
import singleSpa from 'single-spa';
import { gql, useMutation } from '@apollo/client';
import {
  FdTypography,
  FdButton,
  FdSkeleton,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { removeGroups } from '../../graphql/mutations';
import GroupsTable from '../Group/GroupsTable';
import { successToastMessage } from '../../shared/utils/toast';
import { sortedByDate } from '../../shared/utils/dateUtils';
import {
  listGroupsByOrgId,
  listInvitedUsersByOrg,
} from '../../graphql/queries';

const ManageGroups = () => {
  const { orgId } = useSnapshot(globalStore);

  const {
    data: groupsData,
    loading: groupsLoading,
    refetch: refetchListGroupsByOrgId,
  } = useQueryRecursive(gql(listGroupsByOrgId), {
    variables: {
      orgId,
    },
    skip: !orgId,
    staleTime: { seconds: 0 },
  });

  const {
    data: listInvitedUsersByOrgIdData,
    loading: listInvitedUsersByOrgIdLoading,
  } = useQueryRecursive(gql(listInvitedUsersByOrg), {
    variables: {
      orgId,
      filter: { accepted: { eq: false } },
    },
  });

  const [deleteGroupMutation, { loading: deleteGroupLoading }] = useMutation(
    gql(removeGroups),
    {
      onCompleted: () => {
        successToastMessage('Success! Group deleted');
        refetchListGroupsByOrgId();
      },
    },
  );

  const onDeleteGroup = (row) => {
    const { id } = row;
    deleteGroupMutation({
      variables: {
        groupIds: [id],
      },
    });
  };

  const getInvitedUserData = (id) =>
    listInvitedUsersByOrgIdData?.listInvitedUsersByOrg?.items?.filter(
      (item) => item.groupId === id,
    )?.length || 0;
  const rows =
    groupsData?.listGroupsByOrgId?.items?.map((g) => ({
      id: g.id,
      name: g.name,
      description: g.description,
      updatedAt: g.updatedAt,
      // sum of registered and invited users
      users: (g.users?.items?.length || 0) + getInvitedUserData(g.id),
    })) || [];

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" my={2}>
        <FdTypography variant="h3">List of Groups</FdTypography>
        <FdButton
          variant="primary"
          size="large"
          onClick={() =>
            singleSpa.navigateToUrl('/user-management/groups/create')
          }
        >
          Create New Group
        </FdButton>
      </Box>
      <FdSkeleton
        loading={
          groupsLoading || deleteGroupLoading || listInvitedUsersByOrgIdLoading
        }
        height={530}
      >
        <GroupsTable
          rows={sortedByDate([...rows])}
          onDeleteRow={onDeleteGroup}
        />
      </FdSkeleton>
    </Box>
  );
};
export default ManageGroups;
